export const navItems = [
  { name: "About", link: "#about" },
  { name: "Projects", link: "#projects" },
  { name: "Services", link: "#services" },
  { name: "Testimonials", link: "#testimonials" },
  { name: "Contact", link: "#contact" },
];

export const gridItems = [
  {
    id: 1,
    title:
      "Our strength lies in our collaborative approach, which puts our clients at the center of everything we do.",
    description: "",
    className: "lg:col-span-3 md:col-span-6 md:row-span-4 lg:min-h-[60vh]",
    imgClassName: "w-full h-full",
    titleClassName: "justify-end",
    img: "/b1.svg",
    spareImg: "",
  },
  {
    id: 2,
    title: "We are very flexible with time zone communications",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-start",
    img: "",
    spareImg: "",
  },
  {
    id: 3,
    title: "Our tech stack",
    description: "We are constantly try to improve",
    className: "lg:col-span-2 md:col-span-3 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-center",
    img: "",
    spareImg: "",
  },
  {
    id: 4,
    title: "We are Tech enthusiasts with a passion for development.",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-start",
    img: "/grid.svg",
    spareImg: "/b4.svg",
  },

  {
    id: 5,
    title: "Currently building amazing Apps and Websites",
    description: "The Inside Scoop",
    className: "md:col-span-3 md:row-span-2",
    imgClassName: "absolute right-0 bottom-0 md:w-96 w-60",
    titleClassName: "justify-center md:justify-start lg:justify-center",
    img: "/b5.svg",
    spareImg: "/grid.svg",
  },
  {
    id: 6,
    title: "Do you want to start a project together?",
    description: "",
    className: "lg:col-span-2 md:col-span-3 md:row-span-1",
    imgClassName: "",
    titleClassName: "justify-center md:max-w-full max-w-60 text-center",
    img: "",
    spareImg: "",
  },
];

export const projects = [
  {
    id: 1,
    title: "Real Estate Management App",
    des: "The future of property management. Our digital solution to stereamlined operations, seemless tenant communications and unparalleled effeciency in managing properties.",
    img: "/real-estate.png",
    iconLists: ["/re.svg", "/tail.svg", "/ts.svg", "/three.svg", "/fm.svg"],
    link: "",
  },
  {
    id: 2,
    title: "Mahalli",
    des: "Mahalli, an e-commerce platform dedicated to promoting local Bahraini products. This website offers a seamless shopping experience, allowing users to explore and purchase authentic local goods with ease.",
    img: "/mahalli.png",
    iconLists: ["/next.svg", "/tail.svg", "/ts.svg", "/stream.svg", "/c.svg"],
    link: "",
  },
  {
    id: 3,
    title: "Scrap Bahrain",
    des: "Scrap is Bahrain's premier platform revolutionizing automotive parts procurement. This innovative system streamlines scrap yard inventory management with real-time updates, summary dashboards, and user management, while providing customers with an extensive parts database, intuitive search tools, secure purchasing, and order tracking via a mobile app. By enhancing efficiency,             reliability, and sustainability, Scrap sets a new standard in the automotive salvage industry. Experience the future of automotive parts procurement with Scrap.",
    img: "/scrap.png",
    iconLists: ["/re.svg", "/tail.svg", "/ts.svg", "/three.svg", "/c.svg"],
    link: "",
  },
  {
    id: 4,
    title: "Fawateeri - Simplified Billing",
    des: "Introducing Fawateeri, an advanced bill management application designed to streamline and secure your financial obligations. Access, track, and analyze bills effortlessly with customizable warranty reminders and detailed transaction charts. Simplify your financial management with Fawateeri, ensuring peace ofnmind and efficiency.",
    img: "/fawateeri.png",
    iconLists: ["/next.svg", "/tail.svg", "/ts.svg", "/three.svg", "/gsap.svg"],
    link: "",
  },
];

export const testimonials = [
  {
    quote:
      "Working with Venustusy was a game-changer for our company. Their team not only brought our vision to life but also exceeded our expectations in every way. Their attention to detail and innovative approach to software solutions made a significant impact on our operations. We couldn't have asked for a better partner to guide us through our digital transformation. Venustusy truly understands the meaning of customer service and delivers it flawlessly.",
    name: "Salman Khalid",
    title: "Founder of V-Chat",
  },
  {
    quote:
      "Venustusy has been instrumental in our project's success from the very start. Their commitment to excellence and mastery in web development propelled our project forward. They are not just service providers; they are true partners who are deeply invested in our goals and success. Every deadline was met with time to spare, and their responsive communication style kept us in the loop at every stage. I highly recommend Venustusy for anyone looking for top-tier tech solutions and genuine customer engagement.",
    name: "Abdulrahman Ali",
    title: "CTO Of Real Estate App",
  },
  {
    quote:
      "From the initial consultation to the final rollout, our experience with Venustusy was nothing short of stellar. Their strategic insights and cutting-edge technology have significantly boosted our online presence. The team's proactive approach in handling project challenges assured us we made the right decision. Venustusy's dedication to client success is palpable, making them a standout provider in the tech industry. They are not just a company you hire, they are a partner who cares about your growth.",
    name: "Mohammed Waleed",
    title: "Director of Scrap Bahrain",
  },
];

export const companies = [
  {
    id: 1,
    name: "cloudinary",
    img: "/cloud.svg",
    nameImg: "/cloudName.svg",
  },
  {
    id: 2,
    name: "appwrite",
    img: "/app.svg",
    nameImg: "/appName.svg",
  },
  {
    id: 3,
    name: "HOSTINGER",
    img: "/host.svg",
    nameImg: "/hostName.svg",
  },
  {
    id: 4,
    name: "stream",
    img: "/s.svg",
    nameImg: "/streamName.svg",
  },
  {
    id: 5,
    name: "docker.",
    img: "/dock.svg",
    nameImg: "/dockerName.svg",
  },
];

export const workExperience = [
  {
    id: 1,
    title: "Cybersecurity",
    desc: "Protect your IT systems against malware and criminal attacks with cybersecurity analysis services from Venustusy.",
    className: "md:col-span-2",
    thumbnail: "/cybersecurity.svg",
  },
  {
    id: 2,
    title: "Website Development",
    desc: "Design and Develop optimized Web browsing solutions to ensure your end-users can quickly and conveniently accomplish their tasks whenever they are online.",
    className: "md:col-span-2", // change to md:col-span-2
    thumbnail: "/web.svg",
  },
  {
    id: 3,
    title: "Software Consultation",
    desc: "Whatever your software challenge is, our Solution Architecture experts are ready to help you with complex processes.",
    className: "md:col-span-2", // change to md:col-span-2
    thumbnail: "/consulting.svg",
  },
  {
    id: 4,
    title: "Mobile App Development",
    desc: "Whether Native, Hyprid, cross-platform, or progressive web apps. We excel in custom mobile app development through the synergy of expert teams and efficient processes.",
    className: "md:col-span-2",
    thumbnail: "/mobile.svg",
  },
  {
    id: 5,
    title: "Training and Development",
    desc: "Venustusy offers internships for university students, providing hands-on experience in software development, cybersecurity, and project management. Our focus is on practical learning and real-world projects.",
    className: "md:col-span-2",
    thumbnail: "/training.svg",
  },
  {
    id: 6,
    title: "Cloud Computing",
    desc: "Whether Native, Hyprid, cross-platform, or progressive web apps. We excel in custom mobile app development through the synergy of expert teams and efficient processes.",
    className: "md:col-span-2",
    thumbnail: "/cloudcomputing.svg",
  },
];

export const socialMedia = [
  {
    id: 1,
    img: "/git.svg",
    link: "",
  },
  {
    id: 2,
    img: "/twit.svg",
    link: "",
  },
  {
    id: 3,
    img: "/link.svg",
    link: "",
  },
];
